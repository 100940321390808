// i18next-extract-mark-ns-start products-wave-ewap

import { WAVE_CAPABILITIES_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWaveWorkflowsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const digitaltwin = getImage(data.digitaltwin.childImageSharp.gatsbyImageData);
	const acoustic = getImage(data.acoustic.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							In order to provide transformational engineering capability, an engine plant modelling toolchain must not only deliver
							accurate results but also allow for easy integration with the CAE processes of our customers. By providing a platform for
							the creation of efficient workflows, WAVE and WAVE-RT help drive productivity and reduce cost across a range of applications.
						</p>
					</Trans>

					{digitaltwin && (
						<p>
							<GatsbyImage image={digitaltwin} alt="WAVE Real-time digital twin development workflow" />
							<Trans i18nKey="small_1"><small>Real-time digital twin development workflow</small></Trans>
						</p>
					)}

					<Trans i18nKey="content_2">
						<ul>
							<li>Powerful and fast post-processing environment</li>
							<li>Multi-platform computational job management system</li>
							<li>Advanced model calibration capabilities</li>
							<li>1D/3D co-simulation with Realis and third-party tools</li>
							<li>Open interface with other tools through FMU/FMI technology</li>
							<li>Native support in MATLAB Simulink®</li>
							<li>Productivity automation with Python® scripting</li>
							<li>Compatibility with all major Hardware in-the-Loop environments</li>
							<li>Extensive user programming access</li>
							<li>A comprehensive sample engine models database</li>
						</ul>
					</Trans>

					{acoustic && (
						<p>
							<GatsbyImage image={acoustic} alt="Acoustic development workflow" />
							<Trans i18nKey="small_2"><small>Acoustic development workflow</small></Trans>
						</p>
					)}
				</div>
				<Aside>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_CAPABILITIES_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWaveWorkflowsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave-ewap", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
	digitaltwin: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE_RealTimeDigitalTwinDevelopmentWorkflow.jpg" }) {
		...imageBreaker
	}
	acoustic: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE_AcousticDevelopmentWorkflow.png" }) {
		...imageBreaker
	}
}
`;

